<template>
  <div class="chat-time">
    <span v-if="time">{{time}}</span>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: null,
    },
  },
  data(){
    return{

    }
  },
  watch:{
    //监听时间
    time(newVal,oldVal){
        oldVal = newVal
    }
  }
};
</script>

<style scoped>
.chat-time {
  width: 100%;
  height: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.chat-time span {
  padding: 4px 8px;
  font-size: 12px;
  color: #ffffff;
  background: #dcdfe6;
  border-radius: 10px;
}
</style>