<template>
	<div class="chat-window">
		<div class="chat-title" v-if="val.groupName">{{ val.groupName }}</div>
		<div class="chat-title" v-else>暂无</div>
		<div class="chat-container" ref="chatContainer">
			<!-- <spider-window-friendmessage /> -->
			<spider-window-mymessage ref="SpiderWindowMymessage" @scrpllEnd="toEnd" />
			<!-- :message="allMsgArr" -->
		</div>
		<!-- 发送的输入框 -->
		<spider-send-box :targetUser="val" @sendSuccess="sendSuccess" />
	</div>
</template>

<script>
	import SpiderSendBox from "../spider-send/spider-send-box.vue";
	import spiderWindowFriendmessage from "./spider-window-friendmessage.vue";
	import SpiderWindowMymessage from "./spider-window-mymessage.vue";
	import SpiderWindowTime from "./spider-window-time.vue";
	// import init from "@/assets/js/rongyun.js";
	import {
		mapState
	} from "vuex";
	// const userInfo = {
	//   token:
	//     "Oi1v2hDJnsU+O6a1rNyW6eVuqMnjL/Jr7fScDl/ZhUP4UruHdt/k95PgPVCwC2SJ@semu.cn.rongnav.com;semu.cn.rongcfg.com",
	//   appKey: "cpj2xarlcmoin",
	// };
	export default {
		components: {
			spiderWindowFriendmessage,
			SpiderWindowMymessage,
			SpiderWindowTime,
			SpiderSendBox,
		},
		data() {
			return {
				loginMsg: {}, //所有信息
				userInfo: {}, //融云实例化实例化本账号的用户信息
				sendMsg: [], //发送的信息
				receiveMsg: [], //接收的信息
				val: {}, //选中的目标群组或者人
				allMsgArr: [], //全部的消息  分开写得话很难去操作 收发顺序 目前统一都在spider-window-mymessage去处理
			};
		},
		computed: {
			...mapState({
				//获取到vuex融云实例化的数据
				RongIMClient: (state) => state.rongyun.RongIMClient,
				targetId: (state) => state.rongyun.targetId, //当前聊天的目标id
			}),
		},
		created() {
			let userInfo = JSON.parse(localStorage.getItem("loginMsg")); //存在缓存的用户信息
			this.loginMsg = userInfo; //用户信息
			// this.userInfo.appKey = "k51hidwqkg3nb"; //appkey
			// this.userInfo.token = userInfo.tripartiteToken;
		},
		watch: {
			//监听targetId 正在聊天的目标id
			targetId(newVal, oldVal) {
				oldVal = newVal;
			},
		},
		mounted() {},
		methods: {
			//传过来的值 秘书台
			init(val) {
				this.sendMsg = []; //初始化清空发送的消息
				this.receiveMsg = []; //初始化清空接收的消息
				this.allMsgArr = []; //初始化全部消息清空
				this.$refs.SpiderWindowMymessage.changeFirstStatus();
				if (val) {
					this.val = val;
				}
				this.val.groupName = this.val.groupName ?
					this.val.groupName :
					this.val.nickName;
				this.watchM(); //监听
				// this.getUnreadNum(); //获取未读信息
				//初始化聊天信息
				this.$refs.SpiderWindowMymessage.init(this.val, this.loginMsg);
			},
			//聊天框滚动到底部
			toEnd() {
				this.$nextTick(() => {
					let middle = this.$refs["chatContainer"];
					middle.scrollTop = middle.scrollHeight;
				});
			},
			//发送成功传来的值
			sendSuccess(val) {
				this.$refs.SpiderWindowMymessage.addSendMsg(val);
				// this.sendMsg.push(val);
				// this.allMsgArr.push(Object.assign(val, this.loginMsg));
				// this.$store.dispatch("changeMsgNum", this);
			},
			//获取未读消息
			getUnreadNum() {
				const conversationTypes = [RongIMLib.CONVERSATION_TYPE.GROUP];
				this.RongIMClient.Conversation.getTotalUnreadCount(
					false,
					conversationTypes
				).then(function(totalUnreadCount) {
					// console.log("获取未读总数成功", totalUnreadCount);
				});
			},
			//slider-bar 是主要链接融云的地方 但是会在spider-window-messagebox文件从新再连接 就是点击了某个人或者群组聊天的时候 有些值会修改的方便些
			//监听
			watchM() {
				this.RongIMClient.watch({
					// 监听会话列表变更事件, 触发时机：会话状态变化（置顶、免打扰）、会话未读数变化（未读数增加、未读数清空）、会话 @ 信息、会话最后一条消息变化
					conversation: (event) => {
						// console.log("event更新的会话列表", event.updatedConversationList);
						// 假定存在 getExistedConversationList 方法，以获取当前已存在的会话列表数据
						// const conversationList = getExistedConversationList();
						// 发生变更的会话列表
						// const updatedConversationList = event.updatedConversationList;
						// 通过 im.Conversation.merge 计算最新的会话列表
						// const latestConversationList = _this.RongIMClient.Conversation.merge({
						//   conversationList,
						//   updatedConversationList,
						// });
					},
					// 监听消息通知
					message: (event) => {
						// 新接收到的消息内容
						const message = event.message;

						this.$store.dispatch("changeMsgNum", this);
						if (event.message.targetId == this.targetId) {
							if (message.content.content) {
								setTimeout(() => {
									console.log("event更新的消息列表", event.message.from);
									if (event.message.from == "person") {
										if (this.$refs.SpiderWindowMymessage) {
											this.$refs.SpiderWindowMymessage.addSendMsg(
												Object.assign(message, this.val)
											);
										}
									} else {
										//请求每个信息的头像
										this.$api.member
											.memberInfoById(event.message.senderUserId)
											.then((ress) => {
												let obj = event.message;
												obj.avatar = ress.result.avatar;
												if (message.content.content) {
													if (this.$refs.SpiderWindowMymessage) {
														this.$refs.SpiderWindowMymessage.addSendMsg(obj);
													}
												}
											});
									}
								}, 200);
							}
						}

						setTimeout(() => {
							this.$store.dispatch('actionChangeGroup', this)
							this.$store.dispatch('changeMsgNum', this)
							this.$store.commit('changeGroupList')
						}, 200)
						if (!message.content.typingContentType) {
							this.$store.commit("changeMsgList");
						}
						//  this.$store.commit('changeMsgList')
						this.$store.commit("updateMsgList", this); //仅仅通知是否更新
						// this.$store.dispatch('getMessageList',this)

						this.receiveMsg.push(Object.assign(message, this.val));

						if (event.message.senderUserId != this.val.memberId) {
							return;
						}
						if (message.content.content) {
							this.allMsgArr.push(message);
						}

						// console.log("看下消息",this.allMsgArr)
						this.$store.commit("setSendMsgArr", this.receiveMsg); //放进vuex中 私信列表从vuex中取
					},
					// 监听 IM 连接状态变化
					// status: (event) => {
					//   console.log("connection status:", event);
					//   if (event.status == 1) {
					//     console.log("链接中");
					//   }
					//   if (event.status == 0) {
					//     console.log("链接成功");
					//     this.RongIMClient.Conversation.getList({
					//       count: 30,
					//       startTime: 0,
					//       order: 0,
					//     }).then((conversationList) => {
					//       // console.log("获取会话列表成功", conversationList);
					//     });
					//     // 1413421948019470337
					//   }
					// },

					// 监听聊天室 KV 数据变更
					chatroom(event) {
						/**
						 * 聊天室 KV 存储数据更新
						 * @example
						 * [
						 *  {
						 *    "key": "name",
						 *    "value": "我是小融融",
						 *    "timestamp": 1597591258338,
						 *    "chatroomId": "z002",
						 *    "type": 1 // 1: 更新（ 含:修改和新增 ）、2: 删除
						 *  },
						 * ]
						 */
						const updatedEntries = event.updatedEntries;
					},
					expansion(event) {
						/**
						 * 更新的消息拓展数据
						 * @example {
						 *    expansion: { key: 'value' },      // 设置或更新的扩展值
						 *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
						 * }
						 */
						const updatedExpansion = event.updatedExpansion;
						/**
						 * 删除的消息拓展数据
						 * @example {
						 *    deletedKeys: ['key1', 'key2'],    // 设置或更新的扩展值
						 *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
						 * }
						 */
						const deletedExpansion = event.deletedExpansion;
					},
					// 监听离线消息拉取完成
					pullFinished() {
						// console.log("拉取离线消息完成");
					},
				});
			},
		},
	};
</script>

<style scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		display: flex;
		flex-direction: column;
		background: #f5f7fa;
		border-left: 1px solid #dcdfe6;
		overflow: auto;
	}

	.chat-window .chat-title {
		width: 100%;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #f5f7fa;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-window .chat-container {
		min-height: 100px;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
		overflow-y: auto;
	}
</style>