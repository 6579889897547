<template>
  <content>
    <div class="friend-message">
      <img src="@/assets/images/qrcode.png" />
      <div class="chat-content pad">
        东方闪电阿达qsa水水水水水水水水水水水水水水水水水水水水水
      </div>
    </div>
    <div class="friend-message">
      <img src="@/assets/images/qrcode.png" />
      <div class="chat-content">
        <spider-window-file />
      </div>
    </div>
  </content>
</template>

<script>
import spiderWindowFile from "./spider-window-file.vue";
export default {
  components: { spiderWindowFile },
};
</script>
<style scoped>
.friend-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.friend-message .chat-content {
  word-break: break-all;
  display: inline-block;
  font-size: 16px;
  color: #313233;
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-left: 8px;
}
.pad {
  padding: 12px;
  box-sizing: border-box;
}
.friend-message .chat-content::before {
  position: absolute;
  left: -8px;
  top: 8px;
  content: "";
  border-right: 10px solid #ffffff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.friend-message img {
  width: 42px;
  height: 42px;
  margin-right: 8px;
  border-radius: 50%;
}
</style>