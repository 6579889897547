<template>
  <div class="my-file">
    <div class="my-file-top" @click="downFile(file)">
      <img :src="typeImgUrl" />
      <div>
        <p>{{ file.name }}</p>
        <p>{{ file.size }}</p>
      </div>
    </div>
    <div class="my-file-bottom">蜘信电脑版</div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      file: {},
      typeImgUrl: require("@/assets/images/wenjian.png"),
      typeImgUrlObj: {
        defaultUrl: require("@/assets/images/wenjian.png"),
        txtUrl: require("@/assets/images/txt.png"),
        wordUrl: require("@/assets/images/word.png"),
        excelUrl: require("@/assets/images/excel.png"),
        zipUrl: require("@/assets/images/yasuowenjian.png"),
        pdfUrl: require("@/assets/images/ic_pdf@2x.png"),
        pptUrl: require("@/assets/images/ppt.png"),
      },
    };
  },
  watch: {
    //监听文件的值
    content(newVal, oldVal) {
      console.log("文件的值1", this.content);
      oldVal = newVal;
    },
  },
  created() {
    this.formatFile();
  },
  methods: {
    //格式化文件
    formatFile() {
      console.log("文件的值2", this.content);
      this.file = this.content;
      let size = Number(this.file.size / 1024 / 1024);
      this.file.size =
        size >= 1 ? size.toFixed(2) + "M" : (size * 1024).toFixed(2) + "KB";
      this.typeImgUrl = this.typeImgUrlObj.defaultUrl;
      if (this.content.type == "xlsx" || this.content.type == "xls") {
        this.typeImgUrl = this.typeImgUrlObj.excelUrl;
      }
      if (this.content.type == "txt" || this.content.type == "text") {
        this.typeImgUrl = this.typeImgUrlObj.txtUrl;
      }
      if (
        this.content.type == "docx" ||
        this.content.type == "doc" ||
        this.content.type == "rtf"
      ) {
        this.typeImgUrl = this.typeImgUrlObj.wordUrl;
      }
      if (this.content.type == "zip" || this.content.type == "rar") {
        this.typeImgUrl = this.typeImgUrlObj.zipUrl;
      }
      if (this.content.type == "pdf") {
        this.typeImgUrl = this.typeImgUrlObj.pdfUrl;
      }
      if (this.content.type == "pptx" || this.content.type == "ppt") {
        this.typeImgUrl = this.typeImgUrlObj.pptUrl;
      }
    },
    //下载
    downFile(val) {
      console.log(val);
      // 创建 a 标签
      const link = document.createElement("a");
      link.href = val.fileUrl;
      link.download = val.name;
      link.click();
      // window.open(val.fileUrl)

    },
  },
};
</script>

<style scoped>
/* 文件 */
.my-file {
  width: 219px;
  height: 101px;
  cursor: pointer;
  word-break: break-all;
  display: inline-block;
  /* padding: 12px; */
  font-size: 14px;
  color: #313233;
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-right: 8px;
}
.my-file::before {
  position: absolute;
  right: -8px;
  top: 8px;
  content: "";
  border-left: 10px solid #ffffff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
/* 文件 */
.my-file .my-file-top {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-bottom: 1px solid #dcdfe6;
}
.my-file .my-file-top div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.my-file .my-file-top div p:nth-child(1) {
  line-height: 20px;
  color: #313233;
}
.my-file .my-file-top div p:nth-child(2) {
  font-size: 12px;
  color: #7b7d80;
}
.my-file .my-file-top img {
  width: 56px;
  height: 56px;
  margin: 0;
  margin-right: 10px;
  border-radius: 0;
}
.my-file .my-file-bottom {
  font-size: 10px;
  color: #abaeb3;
  padding: 5px 12px;
}
</style>